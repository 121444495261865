.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App .branding-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 87px;
  padding: 1.6em 1.4em;
  border-top: 1px solid #eeece8;
  border-bottom: 1px solid #eeece8;
}

.App .site-branding {
  flex: 1 1 auto;
}

.App .custom-logo {
  max-width: 100%;
  max-height: 100px;
  width: auto;
  height: auto;
}

@media screen and (min-width: 50em) {
  .branding-container {
    padding-left: 3.75em;
    padding-right: 3.75em;
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 37.5em) {
  .site-branding {
    max-width: 100%;
  }
}
